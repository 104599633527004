var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "upgradeManagement", staticClass: "upgrade-management" },
    [
      _c(
        "div",
        { ref: "form", staticClass: "form-area" },
        [
          _c(
            "el-form",
            {
              staticClass: "demo-form-inline",
              attrs: { inline: true, model: _vm.form }
            },
            [
              _c(
                "el-form-item",
                [
                  _vm.$store.state.menu.nowMenuList.indexOf("新增") >= 0
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "small" },
                          on: { click: _vm.onAddUpgrade }
                        },
                        [_vm._v("新增")]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          attrs: {
            data: _vm.tableData.slice(
              (_vm.form.currentPage - 1) * _vm.form.pageSize,
              _vm.form.currentPage * _vm.form.pageSize
            ),
            stripe: "",
            height: _vm.tableHeight
          }
        },
        [
          _c("el-table-column", { attrs: { type: "index", label: "序号" } }),
          _c("el-table-column", { attrs: { prop: "name", label: "文件名称" } }),
          _c("el-table-column", {
            attrs: { prop: "producerName", label: "厂家名称" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm.producerObj[Number(scope.row.makerId)]) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "path",
              label: "升级包路径",
              "show-overflow-tooltip": true
            }
          }),
          _c("el-table-column", {
            attrs: { prop: "version", label: "版本号" }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "createTime",
              width: 180 * this.$store.state.vehicle.screenCoefficient,
              label: "创建时间"
            }
          }),
          _c("el-table-column", {
            attrs: { label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm.$store.state.menu.nowMenuList.indexOf("删除") >= 0
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              type: "text",
                              size: "small",
                              sort: "primary"
                            },
                            on: {
                              click: function($event) {
                                return _vm.onDelUpgrade(scope.row)
                              }
                            }
                          },
                          [_vm._v("删除")]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "div",
        { ref: "pagination", staticClass: "pagination" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
              "page-size": _vm.form.pageSize,
              "current-page": _vm.form.currentPage
            },
            on: {
              "size-change": _vm.onSizeChange,
              "current-change": _vm.onCurrentChange
            }
          })
        ],
        1
      ),
      _vm.dialogVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "添加升级包",
                visible: _vm.dialogVisible,
                width: "30%"
              },
              on: {
                "update:visible": function($event) {
                  _vm.dialogVisible = $event
                }
              }
            },
            [
              _c("add-upgrade", {
                ref: "addUpgrade",
                on: { close: _vm.onDialogClose, onLoading: _vm.onLoading }
              }),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "default", size: "small" },
                      on: {
                        click: function($event) {
                          _vm.dialogVisible = false
                        }
                      }
                    },
                    [_vm._v("取消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        size: "small",
                        loading: _vm.editLoading
                      },
                      on: {
                        click: function($event) {
                          return _vm.onSave()
                        }
                      }
                    },
                    [_vm._v("保存")]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }