
<template>
  <div class="body add-vehicle">
    <el-form
      ref="addForm"
      :model="addForm"
      :rules="rules"
      :inline="true"
      label-width="82px"
      label-position="left"
      class="demo-form-inline dialog-form"
    >
      <el-form-item label="升级包名：" prop="name">
        <el-input style="width: 22vh" v-model="addForm.name" maxlength="32"></el-input>
      </el-form-item>
      <el-form-item label="终端厂家：" prop="makerId">
        <el-select
          v-model="addForm.makerId"
          clearable
          placeholder="请选择终端厂家"
        >
          <el-option
            v-for="i in producerList"
            :label="i.dictValue"
            :value="i.dictCode"
            :key="i.dictCode"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="版本：" prop="version">
        <el-input style="width: 22vh" v-model="addForm.version"  maxlength="32"></el-input>
      </el-form-item>
      <el-form-item label="升级包：" prop="path">
        <el-upload
          ref="upload"
          class="uploadAndDelFile"
          :multiple="false"
          :auto-upload="true"
          :on-error="uploadFalse"
          :on-success="uploadSuccess"
          :before-upload="beforeUpload"
          :before-remove="beforeRemove"
          :action="uploadUrl()"
          :headers="getHeader()"
          :limit="1"
          :on-exceed="handleExceed"
          :file-list="fileList"
        >
          <el-button type="primary" size="small" class="btn"
            >添加升级包</el-button
          >
        </el-upload>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { addUpgrade, queryDictsByCodes } from '@/api/lib/api.js'
import config from '@/api/static/config'

export default {
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      uploadURL: '',
      addForm: {
        name: '',
        makerId: '',
        path: '',
        version: ''
      },
      rules: {
        name: [{ required: true, message: '请输入升级包名', trigger: 'blur' }],
        makerId: [
          { required: true, message: '请选择终端厂家', trigger: 'blur' }
        ],
        version: [{ required: true, message: '请输入版本', trigger: 'blur' }],
        path: [{ required: true, message: '请选择上传文件', trigger: 'change' }]
      },
      producerList: [],
      formData: '',
      fileList: []
    }
  },
  methods: {
    // 新增/修改
    onSave() {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          this.$emit('onLoading', true)

          addUpgrade(this.addForm).then((res) => {
            if (res.code === 1000) {
              this.$message.success('新增成功')
              this.clsoeDia(res.code === 1000)
            } else {
              this.$message.error(res.msg)
              this.$emit('onLoading', false)
            }
          })
        } else {
          return false
        }
      })
    },
    /** 取消 */
    clsoeDia(flag) {
      this.$emit('close', flag)
    },
    /* 接口请求头 */
    getHeader() {
      return {
        Authorization: sessionStorage.getItem('token') || localStorage.getItem('token'),
        token: sessionStorage.getItem('token') || localStorage.getItem('token')
      }
    },
    /* 上传接口 */
    uploadUrl() {
      return config.apiBaseUrl + 'base/devUpgrade/ftpUpload'
    },
    beforeUpload(file) {
      // let checkMsg = this.check_file(file);
      let checkMsg = ''
      if (checkMsg != '') {
        this.$message.error(checkMsg)
        return
      }
    },
    /**删除文件 */
    beforeRemove() {
      this.addForm.path = ''
    },
    /**文件格式验证 */
    check_file(file) {
      let msg = ''
      let isLt2M = file.size / 1024 / 1024 < 10
      if (!isLt2M) {
        msg = '上传模板大小不能超过 10MB!'
      }
      return msg
    },

    /* 添加文件成功时触发函数 上传到后台 */
    uploadSuccess(response, file, fileList) {
      if (response.code == 1000) {
        this.$message.success('文件导入成功')
        this.addForm.path = response.data.url
      } else {
        this.$message.error(response.msg)
        this.addForm.path = ''
      }
    },
    /**文件上传失败！ */
    uploadFalse(response, file, fileList) {
      this.$message.error('文件上传失败！')
      this.addForm.path = ''
    },
    /**只能选一个 */
    handleExceed(files, fileList) {
      this.$message.warning(`请先删除文件再重新选择`)
    },
    /** 获取数据字典 */
    getDicts() {
      queryDictsByCodes({ parentCodes: 'SBCJ' }).then((res) => {
        if (res.code === 1000) {
          this.producerList = res.data.SBCJ
        }
      })
    }
  },
  created() {
    this.getDicts()
  },
  mounted() {}
}
</script>

<style lang="scss" scoped>
.add-vehicle {
  @include themify() {
    .form-title {
      font-size: 14px;
      color: themed('n7');
      margin-bottom: 10px;
    }
    .form-title2 {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>
