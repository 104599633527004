var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "body add-vehicle" },
    [
      _c(
        "el-form",
        {
          ref: "addForm",
          staticClass: "demo-form-inline dialog-form",
          attrs: {
            model: _vm.addForm,
            rules: _vm.rules,
            inline: true,
            "label-width": "82px",
            "label-position": "left"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "升级包名：", prop: "name" } },
            [
              _c("el-input", {
                staticStyle: { width: "22vh" },
                attrs: { maxlength: "32" },
                model: {
                  value: _vm.addForm.name,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "name", $$v)
                  },
                  expression: "addForm.name"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "终端厂家：", prop: "makerId" } },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "请选择终端厂家" },
                  model: {
                    value: _vm.addForm.makerId,
                    callback: function($$v) {
                      _vm.$set(_vm.addForm, "makerId", $$v)
                    },
                    expression: "addForm.makerId"
                  }
                },
                _vm._l(_vm.producerList, function(i) {
                  return _c("el-option", {
                    key: i.dictCode,
                    attrs: { label: i.dictValue, value: i.dictCode }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "版本：", prop: "version" } },
            [
              _c("el-input", {
                staticStyle: { width: "22vh" },
                attrs: { maxlength: "32" },
                model: {
                  value: _vm.addForm.version,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "version", $$v)
                  },
                  expression: "addForm.version"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "升级包：", prop: "path" } },
            [
              _c(
                "el-upload",
                {
                  ref: "upload",
                  staticClass: "uploadAndDelFile",
                  attrs: {
                    multiple: false,
                    "auto-upload": true,
                    "on-error": _vm.uploadFalse,
                    "on-success": _vm.uploadSuccess,
                    "before-upload": _vm.beforeUpload,
                    "before-remove": _vm.beforeRemove,
                    action: _vm.uploadUrl(),
                    headers: _vm.getHeader(),
                    limit: 1,
                    "on-exceed": _vm.handleExceed,
                    "file-list": _vm.fileList
                  }
                },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "btn",
                      attrs: { type: "primary", size: "small" }
                    },
                    [_vm._v("添加升级包")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }